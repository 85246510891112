import React from 'react'
import './header.css'
import { PersonCircle } from 'react-bootstrap-icons'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const index = () => {

  return ( 
    <div>
      <Navbar className='fixed-top border-bottom' collapseOnSelect bg='white' expand='lg' >
        <Container fluid>
          <Navbar.Brand as={Link} to ='/'>
            <img
              src={'/assets/images/logos/logo_consulta.png'}
              className='logo-header d-inline-block align-top'
              loading='lazy'
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
              <Nav
                className="nav-header me-auto my-2 my-lg-0"
                navbarScroll
              >
              </Nav>
              <Nav>
                <Nav.Link eventKey={1} as={Link} to ='/inicio'>Inicio</Nav.Link>
                <Nav.Link eventKey={2} as={Link} to ='/informacion'>Información</Nav.Link>
                <Nav.Link eventKey={3} as={Link} to ='/temas'>Temas</Nav.Link>
                <Nav.Link eventKey={4} as={Link} to ='/mapa'>Mapa</Nav.Link>
                <Nav.Link eventKey={5} as={Link} to ='/descargable'>Descargable</Nav.Link>
                <Nav.Link eventKey={6} as={Link} to ='/equipo' >Equipo</Nav.Link>
                <Nav.Link eventKey={7} href='action7'>
                  <PersonCircle fontSize={25} />
                </Nav.Link>
              </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default index;