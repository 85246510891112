import React from "react";
import { Row, Col } from "react-bootstrap";
import { HashLink as MyLink } from 'react-router-hash-link';

const Introduccion = (props) => {
    return (
        <div className={`sectionScroll pt-5 pb-5 section-introduccion`}>
            <div className='py-3'></div>
                <h1 className='text-title'>
                    Temas
                </h1>
            <Row className='px-3'>
                <Col className='py-4' xs={6} md={6} lg={4}>
                <MyLink to={'/temas#tema_1'} onClick={()=> {props.setTema('tema_1')}}>
                    <img
                    src={'/assets/images/temas/botones/botones_1.png'}
                    className='img-ages col-md-3 col-lg-5 d-inline-block align-top img-fluid shadow-4'
                    loading='lazy'
                    />
                </MyLink>
                </Col>
                <Col className='py-4' xs={6} md={6} lg={4}>
                <MyLink to={'/temas#tema_2'} onClick={()=> {props.setTema('tema_2')}}>
                    <img
                    src={'/assets/images/temas/botones/botones_2.png'}
                    className='img-ages col-md-3 col-lg-5 d-inline-block align-top img-fluid shadow-4'
                    loading='lazy'
                    />
                </MyLink>
                </Col>
                <Col className='py-4' xs={6} md={6} lg={4}>
                <MyLink to={'/temas#tema_3'} onClick={()=> {props.setTema('tema_3')}}>
                    <img
                    src={'/assets/images/temas/botones/botones_3.png'}
                    className='img-ages col-md-3 col-lg-5 d-inline-block align-top img-fluid shadow-4'
                    loading='lazy'
                    />
                </MyLink>
                </Col>
                <Col className='py-4' xs={6} md={6} lg={4}>
                <MyLink to={'/temas#tema_4'} onClick={()=> {props.setTema('tema_4')}}>
                    <img
                    src={'/assets/images/temas/botones/botones_4.png'}
                    className='img-ages col-md-3 col-lg-5 d-inline-block align-top img-fluid shadow-4'
                    loading='lazy'
                    />
                </MyLink>
                </Col>
                <Col className='py-4' xs={6} md={6} lg={4}>
                <MyLink to={'/temas#tema_5'} onClick={()=> {props.setTema('tema_5')}}>
                    <img
                    src={'/assets/images/temas/botones/botones_5.png'}
                    className='img-ages col-md-3 col-lg-5 d-inline-block align-top img-fluid shadow-4'
                    loading='lazy'
                    />
                </MyLink>
                </Col>
                <Col className='py-4' xs={6} md={6} lg={4}>
                <MyLink to={'/temas#tema_6'} onClick={()=> {props.setTema('tema_6')}}>
                    <img
                    src={'/assets/images/temas/botones/botones_6.png'}
                    className='img-ages col-md-3 col-lg-5 d-inline-block align-top img-fluid shadow-4'
                    loading='lazy'
                    />
                </MyLink>
                </Col>
            </Row>
        </div>
    );
};

export default Introduccion;