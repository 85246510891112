import React from 'react'
import './principal.css'
import { Row, Col } from 'react-bootstrap'
import Carrusel from './carrusel';

const Principal = () => {
  return (
    <div id='principal' className='section-principal sectionScroll pt-5 pb-5 mt-5' >
      <div className="container roboto-condensed">
        <Row className="align-items-center" >
            <Col xs={12} className='col-md-12 col-xl-11 float-center'>
              <Carrusel />
            </Col>
            <Col xs={12} className='pt-4 pb-2' >
              <h4>
              Consulta los resultados aquí:
              </h4>
            </Col>
            <Col className='py-3' lg={4}>
              <Row className='align-items-center'>
                <Col xs={1}></Col>
                <Col xs={3} className='align-right' >
                  <a href='#resultado_por_tema'>
                    <img 
                      src={'/assets/images/urnita/transformaciones/pergamino.png'}
                      className='img-transform col-10 col-sm-10 col-md-6 col-lg-12 d-inline-block img-fluid shadow-4'
                    />
                  </a>
                </Col>
                <Col xs={7}>
                  <a href='#resultados_por_tema' className='btn btnTema' >
                    Consulta resultados por tema
                  </a>
                </Col>
                <Col xs={1}></Col>
              </Row>
            </Col>
            <Col className='py-3' lg={4}>
              <Row className='align-items-center'>
              <Col xs={1}></Col>
                <Col xs={3}>
                  <a href='#resultados_por_estado'>
                    <img 
                      src={'/assets/images/urnita/transformaciones/puntero.png'}
                      className='img-transform col-10 col-sm-10 col-md-6 col-lg-12 d-inline-block align-center img-fluid shadow-4'
                    />
                  </a>
                </Col>
                <Col xs={7}>
                  <a href='#resultados_por_estado' className='btn btnEstado' >
                    Consulta resultados por estado
                  </a>
                </Col>
                <Col xs={1}></Col>
              </Row>
            </Col>
            
            <Col className='py-3' lg={4}>
            <Row className='align-items-center'>
                <Col xs={1}></Col>
                <Col xs={3}>
                  <a href='#newsletter'>
                    <img 
                      src={'/assets/images/urnita/transformaciones/altavoz.png'}
                      className='img-transform col-10 col-sm-10 col-md-6 col-lg-12 d-inline-block align-center img-fluid shadow-4'
                    />
                  </a>
                </Col>
                <Col xs={7}>
                  <a href='#newsletter' className='btn btn-light' >
                    Suscríbete al newsletter
                  </a>
                </Col>
                <Col xs={1}></Col>
              </Row>
            </Col>
        </Row>
      </div>
    </div>
  )
};

export default Principal;
