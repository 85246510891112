import React, { useEffect, useState } from 'react'
import { Container, Form, Button } from 'react-bootstrap'
import Pais from './pais'
import './mapa.css';
import { estados } from './pais/estados';
import Popup from '../../structure/popup';
import { Document, Page } from 'react-pdf';

const Mapa = () => {
  const [estadoIndex, setEstadoIndex] = useState("0");
  const [enabled, setEnabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    if (estadoIndex !== "0") {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [estadoIndex]);

  const selectEstado = (event) => {
    const value = event.target.value;
    setEstadoIndex(value);
  };

  const selectAndShowEstado = (value) => {
    setEstadoIndex(value);
    setShowModal(true);
  }
  
  return (
    <div>
      <Container className='mt-5 pt-4'>
        <div className="title">
          <h2>
            Mapa de resultados por estado
          </h2>
        </div>
        <hr />
        <div className="content row">
          <div className="left-top-side col-12 col-md-4 col-lg-3">
            <h4>Selecciona un estado del país</h4>
            <div className="description row">
              <div className="urnita col-6 col-md-12">
                <img
                  src={'/assets/images/urnita/transformaciones/puntero.png'}
                  className='d-inline-block align-top imgUrnita'
                  loading='lazy'
                />
              </div>
              <p className='text-justify col-6 col-md-12 py-4'>
                Selecciona un estado en el mapa o selecciónalo desde la siguiente lista de estados:
              </p>
            </div>
            <div className="form">
              <Form.Select
                aria-label="Seleccina un estado"
                onChange={selectEstado}
                value={estadoIndex}
              >
                {
                  estados.map((estado, index) => (
                    <option key={index} value={index}>
                      {estado.name}
                    </option>
                  ))
                }
              </Form.Select>
              <div className="download col-md-12 d-grid gap-1 py-3">
                <Button
                  variant="primary"
                  onClick={handleShowModal}
                  className={`btn btn-purple${!enabled ? ' disabled' : ''}`}
                >
                  Visualizar infografía
                </Button>
                <Popup
                  show={showModal}
                  handleClose={handleCloseModal}
                  title={estados[estadoIndex].name}
                >
                  <Document
                    file={estados[estadoIndex].pdfFile}
                    onLoadSuccess={() => {}}
                    width='100%'
                  >
                    <Page pageNumber={1} />
                  </Document>
                </Popup>
              </div>
            </div>
          </div>
          <div className="right-bottom-side col-12  col-md-8 col-lg-9">
            <div className="show-map">
              <Pais
                currentEstadoIndex={estadoIndex}
                updateEstadoIndex={selectAndShowEstado}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Mapa
