import React from 'react'
import './newsletter.css'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { CaretUpFill } from 'react-bootstrap-icons'


const newsletter = () => {
  return (
    <div id='newsletter' className='sectionScroll section-News pt-5 pb-5'>
      <div className='py-4'></div>
      <div className='div-link-inicio'>
        <a href='#principal' className='link-inicio'>
          <CaretUpFill />
          Inicio
        </a>
      </div>
        <h1 className='text-title'>
          Suscríbete al newsletter
        </h1>
      <div className='py-2'></div>
      <Container className='container-subscribe'>
        <Row className='align-items-center'>
          <Col xs={12} lg={6} className='vertical-center'>
            <img
              src={'/assets/images/urnita/urnita-frente.png'}
              className='col-lg-6 col-md-3 col-sm-4 col-3 py-4 d-inline-block align-center img-fluid shadow-4'
              loading='lazy'
            />
          </Col>
          <Col xs={12} lg={5} className='vertical-center'>
            <Row className='row-suscribe'>
              <Col xs={5} className='vertical-center'>
                <img
                  src='/assets/images/urnita/transformaciones/altavoz.png'
                  className='img-altavoz'
                  loading='lazy'
                />
              </Col>
              <Col xs={7} className='text-left vertical-center'>
                No te pierdas toda la información, suscríbete a nuestro newsletter y recibe todas las actualizaciones
              </Col>
            </Row>

            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label></Form.Label>
                <Form.Control type="email" placeholder="Ingresa tu correo: ejemplo@ejemplo.com" />
              </Form.Group>
              <Button className='btn-subscribe' variant="ligth" type="submit">
                <h2>Suscríbete</h2>
              </Button>
            </Form>
          </Col>
          <Col xs={1}></Col>
        </Row>
      </Container>
    </div>
  )
}

export default newsletter
