import React from 'react'
import './footer_mobile.css'
import { Col, Container, Row, ButtonGroup } from "react-bootstrap"
import { Facebook, Twitter, Youtube } from "react-bootstrap-icons"


const Footer_mobile = () => {
  return (
    <Container fluid className='d-block d-lg-none footer_scroll' >
      <Row className="align-items-center">
        <Col xs={6}>
          <img
            src={'/assets/images/urnita/transformaciones/casita.png'}
            className='urnita-mb d-inline-block align-top py-3'
            loading='lazy'
          />
        </Col>            
        <Col xs={6}>
          <p className='text-mb' >
            <br/>
            <b>
              Contacto: vinculacion.voto@politicas.unam.mx <br/><br/>
              Teléfono: 55 3237 5322 <br/><br/>
              Dirección: Circuito Mario de la Cueva S/N, Ciudad Universitaria, Delegación Coyoacán, México D.F., C.P. 04510.
            </b>
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={6} >
          <Row>
            <Col xs={5}>
              <a href='https://unam.mx' target='_blank' rel="noopener noreferrer">
                <img
                  src={'/assets/images/logos/unam.png'}
                  className='unam-mb d-inline-block align-top'
                  loading='lazy'
                />
              </a>
            </Col>
            <Col xs={7}>
              <a href='https://ine.mx' target='_blank' rel="noopener noreferrer">
                <img
                  src={'/assets/images/logos/logoINE.png'}
                  className='ine-mb d-inline-block align-top'
                  loading='lazy'
                />
              </a>
            </Col>
          </Row>
        </Col>

        <Col xs={4} >
          <ButtonGroup aria-label="Basic example">
            <a href='https://www.facebook.com/INEMexico' className="me-3" target='_blank' rel="noopener noreferrer">
              <Facebook className='icons-mb facebook-mb' /> 
            </a>
            <a href='https://twitter.com/INEMexico' className="me-3" target='_blank' rel="noopener noreferrer">
              <Twitter className='icons-mb twitter-mb'/>
            </a>
            <a href='https://www.youtube.com/user/IFETV' className="me-3" target='_blank' rel="noopener noreferrer">
              <Youtube className='icons-mb youtube-mb'/>
            </a>
          </ButtonGroup>
        </Col>
        <Col xs={1}></Col>
      </Row>
      <div className='py-2'></div>
    </Container>
  )
}

export default Footer_mobile
