import React from 'react'
import './descargable.css'
import { Col, Row } from 'react-bootstrap'


const Descargable = () => {
  return (
    <div className='mt-5 pt-4 pb-5 container1' >
        <div className='py-4'></div>
        <h1 className='textCard'>
          Material Descargable
        </h1>
        <div className='py-3'></div>
        <Row>
          <Col xs={12} md={6}>
            <a href='/assets/images/descargables/resultados.pdf' download>
              <img
                src={'/assets/images/descargables/resultados.png'}
                className='img-descargable'
                loading='lazy'
              />
              <h2 className='textCard'>
                Descargar Resultados
              </h2>
            </a>
          </Col>
          <Col xs={12} md={6}>
            <a href='https://sticker.ly/s/O5NDMC'>
              <img
                src={'/assets/images/descargables/stickers-cij.png'}
                className='img-descargable'
                loading='lazy'
              />
              <h2 className='textCard'>
                Stickers
              </h2>
            </a>
          </Col>
          <Col xs={12}>
            <div className='py-3'></div>  
          </Col>
          {/* <Col xs={6}>
            <a href='https://sticker.ly/s/O5NDMC'>
              <img
                src={'/assets/images/descargables/stickers-cij.png'}
                className='img-descargable'
                loading='lazy'
              />
              <h2 className='textCard'>
                Stickers
              </h2>
            </a>
          </Col>
          <Col xs={6}>
            <a href='https://sticker.ly/s/O5NDMC'>
              <img
                src={'/assets/images/descargables/stickers-cij.png'}
                className='img-descargable'
                loading='lazy'
              />
              <h2 className='textCard'>
                Stickers
              </h2>
            </a>
          </Col> */}
          <Col xs={12}>
            <div className='py-3'></div>  
          </Col>
        </Row>
    </div>
  )
}

export default Descargable