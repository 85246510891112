import React from "react";
import { Link } from "react-router-dom";

export const presentaciones = {
    tema_1: {
        name: 'Conoce la consulta',
        presentation: [
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema1/Diapositiva1.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema1/Diapositiva2.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema1/Diapositiva3.png',
                tipText: (
                    <div>
                        El INE ha realizado consultas infantiles desde 1997, para conocer más sobre ellas visita el sitio en ine.mx
                        <br />
                        <div className='d-grid gap-1'>
                            <a href='https://www.ine.mx/cultura-civica/consulta-infantil-juvenil' className='btn btn-purple'>
                                Ir al sitio
                            </a>
                        </div>
                    </div>
                ), 
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema1/Diapositiva4.png',
                tipText: (
                    <div>
                        También se elaboraron <b>boletas para lectura en
                        braille</b>. Y los contenidos se <b>tradujeron a las seis
                        lenguas indígenas con mayor número de hablantes</b>&nbsp;
                        monolingües entre 3 y 17 años: ch&apos;ol, mixteco,
                        náhuatl, tlapaneco, tseltal y tsotsil.
                        <br /><br />
                        Conoce las boletas utilizadas en la sección
                        &nbsp;
                        <div className='d-grid gap-1'>
                            <Link to='/descargable' className='btn btn-purple'>
                                Descargar
                            </Link>
                        </div>
                    </div>
                ), 
            },
        ]
    },
    tema_2: {
        name: 'Acerca de ti',
        presentation: [
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema2/Diapositiva1.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema2/Diapositiva2.png',
                tipText: (
                    <div>
                        De acuerdo con el Censo de Población y
                        Vivienda 2020 del Instituto Nacional de
                        Estadística y Geografía (INEGI), la población
                        mexicana total en edades entre 3 y 17 años es <b>más de 32 millones</b> (32,483,904).
                    </div>
                ),
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema2/Diapositiva3.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema2/Diapositiva4.png',
                tipText: (
                    <div>
                        En los cuatro grupos de edad participaron más <b>niñas y adolescentes mujeres</b> que niños y
                        adolescentes hombres.
                    </div>
                ), 
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema2/Diapositiva5.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema2/Diapositiva6.png',
                tipText: (
                    <div>
                        En México se hablan <b>68 lenguas indígenas y español</b>;
                        y en esta consulta participaron niñas, niños y
                        adolescentes que pertenecen a distintos grupos
                        indígenas.
                        México es un país muy diverso, sigamos conservando
                        y fomentando nuestra cultura.
                    </div>
                ), 
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema2/Diapositiva7.png',
                tipText: (
                    <div>
                        Hasta antes del 2019 <b>el pueblo afromexicano no
                        estaba reconocido en la Constitución</b>, aunque
                        éste ha existido desde hace muchísimo tiempo
                        en México.
                        <br /><br />
                        Los pueblos y comunidades afromexicanas en la Constitución
                        en el siguiente sitio: <br />
                        <div className='d-grid gap-1'>
                            <a href='https://revistas.juridicas.unam.mx/index.php/hechos-y=derechos/article/view/13923/15183' className='btn btn-purple'>
                                Ir al sitio
                            </a>
                        </div>
                    </div>
                ),
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema2/Diapositiva8.png',
                tipText: (
                    <div>
                        A veces aunque una <b>discapacidad no se note a
                        simple vista, no significa que no exista</b>. Trata de
                        ser amable con todas las personas; creemos
                        juntas y juntos mejores espacios de convivencia.
                    </div>
                ),
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema2/Diapositiva9.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema2/Diapositiva10.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema2/Diapositiva11.png',
                tipText: (
                    <div>
                        El Artículo 4° de la Constitución Política de los
                        Estados Unidos Mexicanos menciona que <b>&quot;Toda
                        familia tiene derecho a disfrutar de vivienda digna
                        y decorosa&quot;</b>. Y para que ese derecho se cumpla,
                        es importante escuchar las propuestas, ideas y
                        opiniones de mis Urniamix que viven en situación
                        de calle.
                        <br /><br />
                        ¡Recuerda que todas y todos contamos!
                    </div>
                ),
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema2/Diapositiva12.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema2/Diapositiva13.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema2/Diapositiva14.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema2/Diapositiva15.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema2/Diapositiva16.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema2/Diapositiva17.png',
                tipText: (
                    <div>
                        Los <b>roles y estereotipos de género son las
                        funciones, etiquetas o responsabilidades que las
                        mujeres y hombres tienen &quot;porque la sociedad lo
                        dice&quot;</b>. Por ejemplo, las mujeres cuidan a las y los
                        hijos o hacen el quehacer del hogar; mientras
                        que los hombres salen a trabajar o reparan el
                        automóvil.
                        <br /><br />
                        Si quieres conocer más sobre este tema visita el sitio:<br />
                        <div className='d-grid gap-1'>
                            <a href='https://igualdad.ine.mx/' className='btn btn-purple'>
                                Ir al sitio
                            </a>
                        </div>
                    </div>
                ),
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema2/Diapositiva18.png',
            },
        ]
    },
    tema_3: {
        name: 'Cuidado del planeta',
        presentation: [
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema3/Diapositiva1.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema3/Diapositiva2.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema3/Diapositiva3.png',
                tipText: (
                    <div>
                        <b>¡El planeta gira más despacio!</b><br />
                        &quot;El medio ambiente también impacta en los minutos de
                        nuestro reloj. Esto se debe a que la velocidad de la
                        rotación de la Tierra está disminuyendo gradualmente
                        debido a fenómenos como terremotos, erupciones
                        volcánicas, el deshielo de los polos... lo que provoca
                        cambios en la masa; con esto se prevé que en unos <b>180
                        millones de años, la duración de un día en la Tierra será
                        de 25 horas</b>&quot;.
                    </div>
                ), 
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema3/Diapositiva3.png',
                tipText: (
                    <div>
                        ¿Te imaginas cómo sería vivir un día con 25 horas? No
                        olvides hacer algo. Te necesitamos!
                        Más información en el siguiente sitio: <br />
                        <div className='d-grid gap-1'>
                            <a href='https://n9.cl/bbu4l' className='btn btn-purple'>
                                Ir al sitio
                            </a>
                        </div>
                    </div>
                ), 
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema3/Diapositiva4.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema3/Diapositiva5.png',
                tipText: (
                    <div>
                        <b>¡La tala de árboles impacta en el ciclo del agua!</b> <br />
                        &quot;Si continuamos con la tala de árboles al ritmo que
                        vamos, <b>aproximadamente en 100 años podrían
                        desaparecer los bosques y con eso se afectaría el ciclo
                        del agua.</b>
                        Sin árboles que desempeñen su papel, muchas selvas y
                        bosques pueden convertirse rápidamente en áridos
                        desiertos de tierra.&quot;
                        <br /><br />
                        ¿Te imaginas vivir sin agua?
                        Más información en el siguiente sitio:<br />
                        <div className='d-grid gap-1'>
                            <a href='https://n9.cl/xrsps' className='btn btn-purple'>
                                Ir al sitio
                            </a>
                        </div>
                    </div>
                ), 
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema3/Diapositiva6.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema3/Diapositiva7.png',
                tipText: (
                    <div>
                        <b>¡Ingresamos por minuto un camión de
                        basura en el océano!</b>
                        <br /><br />
                        &quot;Hemos reciclado menos del 10% del plástico que
                        producimos a nivel mundial. Esto no refleja la
                        emergencia plástica que hoy enfrentamos, 
                        pues <b>se estima que hasta 12.7 millones de toneladas
                        de plástico ingresan al océano cada año</b>. Esto es
                        equivalente a un camión lleno de desechos que
                        se vierte en las costas cada minuto&quot;
                        <br /><br />
                        Más información en el siguiente sitio:<br />
                        <div className='d-grid gap-1'>
                            <a href='https://n9.cl/x6hwp' className='btn btn-purple'>
                                Ir al sitio
                            </a>
                        </div>
                    </div>
                ), 
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema3/Diapositiva8.png',
            },
        ]
    },
    tema_4: {
        name: 'Cuidado y bienestar',
        presentation: [
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema4/Diapositiva1.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema4/Diapositiva2.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema4/Diapositiva3.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema4/Diapositiva4.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema4/Diapositiva5.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema4/Diapositiva6.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema4/Diapositiva7.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema4/Diapositiva8.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema4/Diapositiva9.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema4/Diapositiva10.png',
                tipText_hidden: (
                    <div>
                        &quot;La discriminación existe cuando una persona no puede
                        disfrutar de sus derechos humanos o de otros derechos
                        legales debido a una diferencia injustificada que se
                        establece en la política, la ley, la cultura, entre otras
                        razones.<br />
                        En todo el mundo, <b>1 de cada 10 personas vive con
                        discapacidad. Sin embargo, en muchas sociedades, las
                        personas con discapacidades tienen que enfrentarse al
                        estigma</b>, a la exclusión y a que las traten con pena o con
                        miedo.&quot;<br />
                        Más información en el siguiente sitio: <br />
                        <div className='d-grid gap-1'>
                            <a href='https://www.amnesty.org/es/what-we-do/discrimination/' className='btn btn-purple'>
                                Ir al sitio
                            </a>
                        </div>
                    </div>
                ), 
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema4/Diapositiva11.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema4/Diapositiva12.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema4/Diapositiva13.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema4/Diapositiva14.png',
                tipText: (
                    <div>
                        &quot;La violencia contra las mujeres y las niñas se define como todo acto
                        de violencia basado en el género que tenga o pueda tener como
                        resultado un daño o sufrimiento físico, sexual o mental para la
                        mujer.&quot;
                        <br />Fuente: Amnistía Internacional. Discriminación
                    </div>
                ),
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema4/Diapositiva15.png',
                tipText: (
                    <div>
                        &quot;En México, un poco <b>más de 66% de las mujeres han sufrido algún
                        tipo de violencia a lo largo de su vida, ya sea en el ámbito familiar,
                        escolar, laboral, en el espacio público o con su pareja</b>.
                        Durante la pandemia de la COVID-19 se ha intensificado la visibilidad
                        de la violencia, ya que al quedarnos en casa, se han pronunciado las
                        desigualdades de género en la vida de las mujeres y las niñas&quot;.
                        <br /><br />
                    </div>
                ),
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema4/Diapositiva16.png',
                tipText: (
                    <div>
                        Urniamix si sufres violencia aquí podrás encontrar los lugares a donde
                        puedes llamar, pedir orientación, apoyo y ayuda en tu entidad
                        federativa. También puedes visitar el siguiente sitio: <br />
                        <div className='d-grid gap-1'>
                            <a href='https://www.gob.mx/inmujeres/articulos/servicios-de-atencion-a-mujeres-en-situacion-de-violencia-de-los-estados' className='btn btn-purple'>
                                Ir al sitio
                            </a>
                        </div>
                    </div>
                ),
            },
        ]
    },
    tema_5: {
        name: 'Derechos Humanos',
        presentation: [
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema5/Diapositiva1.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema5/Diapositiva2.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema5/Diapositiva3.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema5/Diapositiva4.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema5/Diapositiva5.png',
                tipText: (
                    <div>
                        &quot;<b>La salud emocional es el estado de bienestar
                        donde nos sentimos bien con nosotros mismos y
                        con los demás;</b> por lo tanto somos capaces de
                        reconocer nuestras emociones y nos permite
                        enfrentarnos a las adversidades de la vida.&quot;
                    </div>
                ), 
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema5/Diapositiva5.png',
                tipText: (
                    <div>
                        &quot;Según la Organización Mundial de la Salud se
                        calcula que en el mundo <b>el 3.6% de los
                        adolescentes de 10 a 14 años padece un
                        trastorno de ansiedad.</b> También se calcula que el
                        1.1% de los adolescentes de 10 a 14 años
                        padecen depresión.&quot;
                        <br /><br />
                        Información tomada de OMS:<br />
                        <div className='d-grid gap-1'>
                            <a href='https://www.who.int/es/news-room/fact-sheets/detail/adolescent-mental-health' className='btn btn-purple'>
                                Ir al sitio
                            </a>
                        </div>
                    </div>
                ), 
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema5/Diapositiva6.png',
                tipText: (
                    <div>
                        &quot;El derecho a la educación está reconocido <b>en el
                        artículo 3° de la Constitución Política de los Estados
                        Unidos Mexicanos y establece que toda persona tiene
                        derecho a la educación.</b> El Estado impartirá y
                        garantizará la educación inicial, preescolar, primaria,
                        secundaria, media superior y superior.&quot;
                    </div>
                ),
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema5/Diapositiva6.png',
                tipText: (
                    <div>
                        &quot;El Censo de Población y Vivienda 2020 registró que <b>en
                        México 94 % de las niñas y los niños de 6 a 14 años
                        asisten a la escuela&quot;</b>
                        <br /><br />
                        Constitución Política de los Estados Unidos Mexicanos: <br />
                        <div className='d-grid gap-1'>
                            <a href='https://www.diputados.gob.mx/LeyesBiblio/pdf/CPEUM.pdf' className='btn btn-purple'>
                                Ir al sitio
                            </a>
                        </div>
                        Información tomada de INEGI:<br />
                        <div className='d-grid gap-1'>
                            <a href='https://cuentame.ineai.ora.mx/poblacion/asistencia.aspx' className='btn btn-purple'>
                                Ir al sitio
                            </a>
                        </div>
                    </div>
                ),
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema5/Diapositiva7.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema5/Diapositiva8.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema5/Diapositiva9.png',
                tipText: (
                    <div>
                        Según la UNICEF &quot;La igualdad de género, significa que <b>mujeres, 
                        hombres, niñas y niños deban gozar, por igual, de los mismos derechos</b>, 
                        recursos, oportunidades y protecciones&quot;. 

                        &quot;La igualdad de las niñas y las adolescentes es responsabilidad de todas y todos.&quot;
                        <br /><br />
                        Información tomada de UNICEF:
                        <div className='d-grid gap-1'>
                            <a href='https://www.unicef.org/lac/igualdad-de-genero#:~:text=La%20igualdad%20de%20las%20ni%C3%B1as,%2C%20recursos%2C%20oportunidades%20y%20protecciones' className='btn btn-purple'>
                                Ir al sitio
                            </a>
                        </div>
                    </div>
                )
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema5/Diapositiva10.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema5/Diapositiva11.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema5/Diapositiva12.png',
            },
        ]
    },
    tema_6: {
        name: 'Covid-19',
        presentation: [
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema6/Diapositiva1.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema6/Diapositiva2.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema6/Diapositiva3.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema6/Diapositiva4.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema6/Diapositiva5.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema6/Diapositiva6.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema6/Diapositiva7.png',
                tipText: (
                    <div>
                        Los Urniamix-hombres expresaron haber sentido
                        siempre felicidad, protección y tranquilidad.
                        Mientras que las Urniamix-mujeres señalaron haber
                        experimentado, siempre y algunas veces, tristeza,
                        miedo, aburrimiento, enojo y preocupación.
                        <br /><br />
                        Pero recuerda... <b>¡No estás sola y no estás solo!</b>
                        <br /><br />
                        Nuestros amigos de UNICEF nos brindan algunas
                        recomendaciones que pueden ayudarte a pasar un
                        poco mejor esta temporada, ingresa a: 
                        <div className='d-grid gap-1'>
                            <a href='https://n9.cl/17oh' className='btn btn-purple'>
                                Descargar
                            </a>
                        </div>
                    </div>
                ), 
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema6/Diapositiva8.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema6/Diapositiva9.png',
            },
            {
                slideUrl: '/assets/images/temas/presentaciones/Tema6/Diapositiva10.png',
                tipText: (
                    <div>
                        No puedes dejar de leer &quot;Mi héroe eres tú&quot;<br /><br />
                        &quot;Se trata de un importante recurso para Urniamix de todo
                        el mundo con un rotundo mensaje de fondo: que solo
                        podremos vencer la pandemia si todos participamos en
                        su prevención y respuesta. Las niñas y niños incluidos,
                        los refugiados, desplazados y apátridas, también pueden
                        ayudar. <b>Nadie estará protegido si no estamos todos
                        protegidos.</b>&quot;
                        Descarga el libro: <br />
                        <div className='d-grid gap-1'>
                            <a href='https://n9.cl/y2576' className='btn btn-purple'>
                                Descargar
                            </a>
                        </div>
                    </div>
                ),
            },
        ]
    },
};