import React from 'react'
import './resultEstado.css'
import { CaretUpFill } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'

const resultEstado = () => {
  return (
    <div id='resultados_por_estado' className='section-estado sectionScroll pt-5 pb-5' >
      <div className='py-3'></div>
      <div className='div-link-inicio'>
        <a href='#principal' className='link-inicio-estado'>
          <CaretUpFill />
          Inicio
        </a>
      </div>
        <h1 className='text-title'>
          Resultados por estado
        </h1>
      <div className='py-4'>
        <div className='container'>
          <Row>
            <Col xs={12} sm={8} className='vertical-center'>
              <Link to={'/mapa'}>
                <img
                  src='/assets/images/mapa/mapa.png'
                  className='mapa-img'
                  loading='lazy'
                />
              </Link>
            </Col>
            <Col xs={12} sm={4} className='vertical-center'>
              <Row>
                <Col xs={5} sm={12} md={5} className='vertical-center'>
                  <img
                    src='/assets/images/urnita/transformaciones/puntero.png'
                    className='urnita-img'
                    loading='lazy'
                  />
                </Col>
                <Col xs={7} sm={12} md={7} className='text-left'>
                  Conoce las ideas, propuestas y opiniones de las niñas, niños y adolescentes de cada entidad federativa.
                  <br />
                  <b>¡Haz valer sus voces!</b>
                </Col>
              </Row>
              <Col xs={12} className='d-grid gap-1 py-4'>
                <Link
                  to='/mapa'
                  className={'btn btn-lg btn-purple'}
                  role='button'
                >
                  Ver Mapa
                </Link>
              </Col>
            </Col>
          </Row>
        </div>
      </div>

    </div>
  )
}

export default resultEstado
