import React from "react";
import './persona.css'
import { Row, Col } from "react-bootstrap";

const Persona = ({name, email, photo = '/assets/woman_user.png', description }) => {

    return (
        <Row className="persona">
            <Col xs={12} className='photo-equipo'>
                <img
                    src={photo}
                    className='img-person img-fluid shadow-4'
                    loading='lazy'
                />
            </Col>
            <Col xs={12} className='name-equipo'>
                <b>
                    {name}
                </b>
            </Col>
            <Col xs={12} className='email-equipo correo'>
                <a href={`mailto:${email}`}>
                    {email}
                </a>
            </Col>
            <Col xs={12} className='description-equipo text-justify pt-2 pb-4 px-4'>
                <i>{`${description ? `"${description}"` : ''}`}</i>
            </Col>
        </Row>
    );
};

export default Persona;