import React from "react";
import { Button, Modal } from "react-bootstrap";
import './popup.css';

const Popup = (props) => {
	return (
		<Modal show={props.show} onHide={props.handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>{props.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{props.children}
			</Modal.Body>
			{
				props.withFooter
					? <Modal.Footer>
							<Button variant="secondary" onClick={props.handleClose}>
								Close
							</Button>
							<Button variant="primary" onClick={props.handleClose}>
								Save Changes
							</Button>
						</Modal.Footer>
					: null
			}
		</Modal>
	);
};

export default Popup;