import React from 'react';
import Container from '../structure/container';
import Footer from '../structure/footer';
import Header from '../structure/header';
import { Outlet } from 'react-router-dom';

const Main = () => {   
    
    function hideFooter() {
        var scrollContainer = document.getElementById("myScroll");
        var my_footer = document.getElementById("normalFooter");
        if (scrollContainer) {
            //console.log('scroll is null')
            my_footer.style.display= 'none';
        } else {
            //console.log('scroll IS´NT null')
            my_footer.style.display = 'block';
        }
    }

    return (
        <div className='main' onLoad={hideFooter}>
            <Header/>
            <Container>
                <Outlet>                    
                </Outlet>
            </Container>
            <div id='normalFooter'>
                <Footer/>
            </div>
        </div>
    );
};
export default Main;