import React from 'react'
import './footer_index.css'
import BigFooter from './footer_pc'
import SmallFooter from './footer_mobile'

const Footer = () => {
   
    return (
      <footer className='footer-container'>
        <BigFooter />
        <SmallFooter />
      </footer>
    );
};
export default Footer;