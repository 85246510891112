import React, { useState } from 'react'
import SectionContainer from '../section_container';
import Introduccion from './introduccion';
import Presentacion from './presentacion';
import "./temas.css";

const Temas = () => {
  const [tema, setTema] = useState(window.location.hash.substring(1));
  return (
    <SectionContainer>
      <Introduccion setTema={setTema}/>
      {
        tema !== ''
        ? <Presentacion tema={tema}/>
        : null
      }
    </SectionContainer>
  )
}

export default Temas