import React from 'react'
import { Carousel } from 'react-bootstrap';
import { HashLink as MyLink } from 'react-router-hash-link';

const Carrusel = () => {
  return (
      <div className="carrusel">
        <Carousel>
					<Carousel.Item>
						<MyLink to="/descargable">
							<img
								className="item"
								src='/assets/images/banners/carrusel_5.jpg'
								alt='First slide'
							/>
						</MyLink>
					</Carousel.Item>
					<Carousel.Item>
						<MyLink to="/informacion#que_es">
							<img
								className="item"
								src='/assets/images/banners/carrusel_1.png'
								alt='Second slide'
								loading='lazy'
							/>
						</MyLink>
					</Carousel.Item>
					<Carousel.Item>
						<MyLink to="/informacion#cuando">
							<img
								className="item"
								src='/assets/images/banners/carrusel_2.png'
								alt='Third slide'
								loading='lazy'
							/>
						</MyLink>
					</Carousel.Item>
					<Carousel.Item>
						<MyLink to="/informacion#quienes">
							<img
								className="item"
								src='/assets/images/banners/carrusel_3.png'
								alt='Fourth slide'
								loading='lazy'
							/>
						</MyLink>
					</Carousel.Item>
					<Carousel.Item>
						<MyLink to="/informacion#donde">
							<img
								className="item"
								src='/assets/images/banners/carrusel_4.png'
								alt='Fifth slide'
								loading='lazy'
							/>
						</MyLink>
					</Carousel.Item>
				</Carousel>
      </div>
  );

};

export default Carrusel;
