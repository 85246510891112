import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as temas from "./presentaciones";

const Presentacion = (props) => {
    const [currentTema, setCurrentTema] = useState('tema_1');
    const [currentSlide, setCurrentSlide] = useState(0);
    const tema = temas.presentaciones[currentTema];
    const slide = tema.presentation[currentSlide]
    const currentTipText = slide.tipText;
    useEffect(() => {
        if (props.tema !== '') {
            setCurrentTema(props.tema);
            setCurrentSlide(0);
        }
    }, [props.tema]);

    const setAnterior = () => {
        if (currentSlide > 0) {
            setCurrentSlide(currentSlide-1);
        }
    };
    const setSiguiente = () => {
        if (currentSlide + 1 < tema.presentation.length) {
            setCurrentSlide(currentSlide + 1);
        }
    };
    return (
        <div id={currentTema} className={`sectionScroll pt-3 pb-5 section-presentation`}>
            <Container className='mt-5 pt-4'>
                <Row>
                    <Col xs={12} md={9}>
                        <Row>
                            <Col xs={12}>
                                <img
                                    src={slide.slideUrl}
                                    className='presentation'
                                    loading='lazy'
                                />
                            </Col>
                            <Col xs={12}>
                                <Row>
                                    <Col xs={4} className='d-grid gap-2'>
                                        {/* <a onClick={() => setAnterior()}> */}
                                        <button type='button' className='btn btn-purple' onClick={() => setAnterior()}>
                                        {'< Anterior'}
                                        </button>
                                        {/* </a> */}
                                    </Col>
                                    <Col xs={4}>
                                        {`${currentSlide+1} de ${tema.presentation.length}`}
                                    </Col>
                                    <Col xs={4} className='d-grid gap-2'>
                                        <button type='button' className='btn btn-purple' onClick={() => setSiguiente()}>
                                            {'Siguiente >'}
                                        </button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={3} className='vertical-center'>
                        <Row>
                        {
                            currentTipText ?
                            (
                                <React.Fragment>
                                    <Col xs={6} md={12} className='p-2 tip-text text-left roboto-condensed'>
                                        { currentTipText }
                                    </Col>
                                    <Col xs={6} md={12} className='p-2 vertical-center'>
                                        <img
                                            src='/assets/images/urnita/urnita-saludo-alfa.gif'
                                            className='urnita-temas'
                                            loading='lazy'
                                        />
                                    </Col>
                                </React.Fragment>
                            )
                            : (
                                <Col xs={12}className='p-2 vertical-center'>
                                    <img
                                        src='/assets/images/urnita/urnita-saludo-alfa.png'
                                        className='urnita-temas'
                                        loading='lazy'
                                    />
                                </Col>
                            )
                        }
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Presentacion;