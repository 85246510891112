import React from 'react'
import './footer_pc.css'
import { Col, Container, Row, ButtonGroup } from "react-bootstrap"
import { Facebook, Twitter, Youtube } from "react-bootstrap-icons"

const Footer_mobile = () => {
  return (
    <Container fluid className='d-none d-lg-block footer_scroll'>
      <Row className="align-items-center">
        <Col lg={3} >
          <img
            src={'/assets/images/urnita/transformaciones/casita.png'}
            className='d-inline-block align-top urnita-footer py-3 my-3'
            loading='lazy'
          />
        </Col>            
        <Col lg={4} >
          <p className='text-contact'>
            <b>
              Contacto: vinculacion.voto@politicas.unam.mx <br/><br/>
              Teléfono: 55 3237 5322 <br/><br/>
              Dirección: Circuito Mario de la Cueva S/N, Ciudad Universitaria, Delegación Coyoacán, México D.F., C.P. 04510.
            </b>
          </p>
        </Col>
        <Col lg={4} >
          <Container >
            <Row >
              <Col lg={12} className='pb-2'>
                <Row>
                  <Col xs={5}>
                    <a href='https://unam.mx' target='_blank' rel='noopener noreferrer'>
                      <img
                        src={'/assets/images/logos/unam.png'}
                        className='unam-mb d-inline-block align-top'
                        loading='lazy'
                      />
                    </a>
                  </Col>
                  <Col xs={7}>
                    <a href='https://ine.mx' target='_blank' rel='noopener noreferrer'>
                      <img
                        src={'/assets/images/logos/logoINE.png'}
                        className='ine-footer d-inline-block align-top'
                        loading='lazy'
                      />
                    </a>
                  </Col>
                </Row>
              </Col>
              <hr />
              <Col lg={3} />
              <Col lg={3}>
                <ButtonGroup aria-label="Basic example">
                  <a href='https://www.facebook.com/INEMexico' className="me-3" target='_blank' rel="noopener noreferrer">
                    <Facebook className='icons-mb facebook-mb' /> 
                  </a>
                  <a href='https://twitter.com/INEMexico' className="me-3" target='_blank' rel="noopener noreferrer">
                    <Twitter className='icons-mb twitter-mb'/>
                  </a>
                  <a href='https://www.youtube.com/user/IFETV' className="me-3" target='_blank' rel="noopener noreferrer">
                    <Youtube className='icons-mb youtube-mb'/>
                  </a>
                </ButtonGroup>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer_mobile
