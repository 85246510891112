import React from 'react'
import SectionContainer from '../section_container'
import Section from './section'
import Footer from '../../structure/footer'
import { Row, Col } from 'react-bootstrap'

const Informacion = () => {
  return (
    <SectionContainer>
      <Section
        id='que_es'
        logo='/assets/images/temas/fondos/titulos_1.png'
        urnita='/assets/images/urnita/transformaciones/globo_dialogo.png'
        description={<span>
          Es un ejercicio de participación donde las niñas, niños y adolescentes expresan lo que piensan sobre diferentes temas.
          Se hace por dos razones muy importantes:
          <br />
          <br />
          <ol>
            <li><b>Garantizar el derecho a la participación de las niñas, los niños y adolescentes.</b></li>
            <li><b>Garantizar el derecho de que su opinión sea tomada en cuenta.</b></li>
          </ol>
        </span>}
        
        className='section-1'
      />
      <Section
        id='cuando'
        logo='/assets/images/temas/fondos/titulos_2.png'
        urnita='/assets/images/urnita/transformaciones/globo_dialogo.png'
        description={<span>
          Del 1 al 30 de noviembre de 2021 las niñas, niños y adolescentes pudieron participar en la consulta.
          <br />
          <br />
          <b>¡Por primera vez en modalidad virtual a través de la página del INE y de forma presencial!</b>
          <br />
          <br />
          También, y en el mismo periodo, se instalaron casillas en espacios escolares e itinerantes, casillas presenciales en las sedes de los órganos desconcentrados locales y distritales del Instituto, así como en sedes de los Organismos Públicos Locales para que las personas sin acceso a internet pudieran opinar.
        </span>}
        className='section-2'
        mirrorEffect
      />
      <Section
        id='quienes'
        logo='/assets/images/temas/fondos/titulos_3.png'
        urnita='/assets/images/urnita/transformaciones/globo_dialogo.png'
        description={<span>
          En la <b>Consulta Infantil y Juvenil 2021</b>, tomando en cuenta ambas modalidades de participación, expresaron su opinión <b>6,976,839</b> niñas, niños y adolescentes entre <b>3</b> y <b>17</b> años de todo el país, e incluso de otros países <b>(7,951 participaciones en total)</b>.
          <br />
          <br />
          Para participar en la <b>Consulta Infantil y Juvenil 2021</b>, se crearon cuatro tipos de boletas: una boleta accesible para participantes de <b>3</b> a <b>5</b> años de edad o para las personas que no puedan leer o escribir; y un pm a boleta para cada uno de los rangos de <b>6 a 9 años</b>; <b>10 a 13 años</b>, y <b>14 a 17 años</b>. Esto aseguró que las preguntas y la forma en que éstas se plantearon estivieran de acuerdo con el nivel de desarrollo de niñas, niños y adolescentes.
        </span>}
        className='section-3'
      />
      <Section
        id='donde'
        logo='/assets/images/temas/fondos/titulos_4.png'
        urnita='/assets/images/urnita/transformaciones/globo_dialogo.png'
        description={
        <Row>
          <Col xs={12} sm={2}/>
          <Col xs={12} sm={10}>
            <span>
              Los tres estados con menor participación en la CIJ fueron:
              <ol>
                <li><b>Aguascalientes (37.53%), </b></li>
                <li><b>Morelos (34.71%)  y </b></li>
                <li><b>Tlaxcala (31.52%). </b></li>
              </ol>
              Los tres estados con menor participación en la CIJ fueron:
              <ol>
                <li><b>Baja California (6.52%), </b></li>
                <li><b>Chiapas (8.25%) y </b></li>
                <li><b>Nayarit (8.94%).</b></li>
              </ol>
            </span>
          </Col>
        </Row>
        }
        className='section-4'
        mirrorEffect
      />
      <Footer className='sectionScroll' />
    </SectionContainer>
  )
}

export default Informacion