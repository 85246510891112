import React from 'react'
import './informacion.css'
import { Row, Col } from 'react-bootstrap'

const Section = (props) => {
  return (
    <div id={props.id} className={`sectionScroll pt-5 pb-5 section ${props.className}`}>
      <div className="container">
      <Row className='align-items-center' >
        <Col xs={12} className='py-4'>
          <img
            src={props.logo}
            className='d-inline-block align-top img-fluid shadow-4 logo-max'
            loading='lazy'
          />
        </Col>
        <Col xs={12}>
          <Row className={`vertical-children ${props.mirrorEffect ? 'reversed' : '' }`}>
            <Col xs={12} sm={4} className='pb-4'>
              <Row className='vertical-children'>
                <Col xs={5} sm={12}>
                  <img 
                    src={props.urnita}
                    className='img-fluid shadow-4 urnita-max'
                    loading='lazy'
                  />
                </Col>
                <Col xs={6} sm={12} className='small-text'>
                  Información tomada del Resumen de Resultados Sociodemográficos. Consulta Infantil y Juvenil 2021
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={8} className='justify-content pb-2 pl-5 pr-5'>
              <div className="space-text-section">
                <span className='text-section roboto-condensed'>
                  {props.description}
                </span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      </div>
    </div>
  )
}

export default Section;
