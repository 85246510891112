import './App.css';
import Main from './components/main'
import React from 'react';
import Inicio from './components/pages/inicio';
import Informacion from './components/pages/informacion';
import Temas from './components/pages/temas';
import Mapa from './components/pages/mapa';
import Descargable from './components/pages/descargable';
import Equipo from './components/pages/equipo';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';


function App() {
  return (
    <div className="App">      
      <BrowserRouter>
          <Routes>
              <Route path='/' element={ <Main /> }>
                  <Route index element={ <Inicio /> } />
                  <Route path='informacion' element={ <Informacion /> } />
                  <Route path='temas' element={ <Temas /> } />
                  <Route path='mapa' element={ <Mapa /> } />
                  <Route path='descargable' element={ <Descargable /> } />
                  <Route path='equipo' element={ <Equipo /> } />
                  <Route path='*' element={ <Navigate replace to="/"/> }/>
              </Route>
          </Routes> 
      </BrowserRouter>
    </div>
  );
}

export default App;
