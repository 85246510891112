import React from 'react';
import SectionContainer from '../section_container';
import Principal from './principal';
import ResultTema from './resultTema';
import ResultEstado from './resultEstado';
import Newsletter from './newsletter';
import Footer from '../../structure/footer';



const Inicio = () => {
    return (
    <SectionContainer>
      <Principal className='sectionScroll' />
      <ResultTema className='sectionScroll' />
      <ResultEstado className='sectionScroll' />
      <Newsletter className='sectionScroll' />
      <Footer className='sectionScroll' />
    </SectionContainer>
    );
};
export default Inicio;