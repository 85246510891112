import React from "react";
import './equipo.css'
import { Container, Row, Col } from "react-bootstrap";
import Persona from "./persona";

const Equipo = () => {
  const equipo = [
    {
      name: 'Adriana Escamilla',
      photo: '/assets/images/equipo/foto_adriana.png',
      description: 'Cuando era peque quería ser médico porque quería ayudar a las personas, por eso ahora soy politóloga',
      email: 'adrianaescamilla@politicas.unam.mx'
    },
    {
      name: 'Aidee Rodríguez',
      photo: '/assets/images/equipo/foto_aide.png',
      description: 'De pequeña quería ser pintora, bombera, marinera y astronauta, en fin, el límite es cielo y por ahora soy Internacionalista',
      email: 'aidee.rod@politicas.unam.mx'
    },
    {
      name: 'Alejandra Tecorralco',
      photo: '/assets/images/equipo/foto_ale.png',
      description: 'Cuando era chiquita quería conocer amigas de todo el mundo y guardar algo de cada país en mi mochila rosa, por eso estudié Relaciones Internacionales',
      email: 'alejandra_ht@politicas.unam.mx'
    },
    {
      name: 'Ana Flores',
      photo: '/assets/images/equipo/foto_ana.png',
      description: 'Cuando era chiquita quería ser todo, maestra Pokémon, médica, bombera, veterinaria, cuidar plantas, por eso estudié Administración Pública',
      email: 'alflores@politicas.unam.mx'
    },
    {
      name: 'Emerid Cabello',
      photo: '/assets/images/equipo/foto_emerid.png',
      description: 'De pequeño quería ser arquitecto, también me gustaba encontrar patrones en las cosas y los números, creo que por eso termine analizando datos como actuario',
      email: 'emeridoscar@gmail.com'
    },
    {
      name: 'Itxchel Ayala',
      photo: '/assets/images/equipo/foto_ixtchel.png',
      description: 'Cuando era chiquita quería ser actriz, por eso estudié comunicación para tomar fotos, conducir programas de TV y ser la protagonista de mi vida',
      email: 'itxchel95@gmail.com'
    },
    {
      name: 'Ivana Ledesma ',
      photo: '/assets/images/equipo/foto_ivana.png',
      description: 'Cuando era pequeña quería ser astronauta y  también me gustaba mucho la historia del mundo y de las personas que habitan en él. Ahora soy politóloga.',
      email: 'copcaivana@politicas.unam.mx '
    },
    {
      name: 'Karla Vega',
      photo: '/assets/images/equipo/foto_karla.png',
      description: 'De niña quería ser una pintora famosa y tocar el piano, lo más cerca que pude estar de eso es siendo diseñadora y comunicadora visual, aún aprendiendo a tocar la melódica.',
      email: 'karla.vega.uribe@gmail.com'
    },
    {
      name: 'Luz Becerril ',
      photo: '/assets/images/equipo/foto_luz.png',
      description: 'De pequeña me gustaba jugar a ser maestra, trabajar en una empresa y ver videos sobre el universo, estudié matemáticas y mi carrera me permite trabajar de todo eso que alguna vez disfruté jugando',
      email: 'luzbecerril055@gmail.com'
    },
    {
      name: 'Cesar Vega',
      photo: '/assets/images/equipo/foto_cesar.png',
      description: 'De niño nunca me sentí realmente seguro de que quería ser, hoy en día continuó con esa duda, por eso ahora soy una mezcla de oficios que sé que harían feliz a mi niño interior.',
      email: '13.cesarvega@gmail.com'
    },
    {
      name: 'Isael Durán',
      photo: '/assets/images/equipo/foto_isa.png',
      description: 'En mi etapa de niñez siempre imaginé ser un súper heroe para ayudar a los demás. Hoy me uno a un equipo con el objetivo de mejorar la vida de las personas en mi país.',
      email: 'isael191@gmail.com'
    },
  ];
  return(
      <Container className='container-equipo mt-5 pt-4 pb-5' >
        <Row className="align-items-center" >
          <Col xs={12}>
            <img
              src={'/assets/images/logos/consulta-infantil-juvenil-2021-logo.png'}
              className='d-inline-block align-top img-fluid shadow-4'
              loading='lazy'
            />
          </Col>
          <Col xs={12}>
            <br/>
            <h1 className='title-equipo'>¿Quiénes somos?</h1>
            <br/>
          </Col>
          <Col xs={1}></Col>
          <Col xs={10}>
              <p className='text-equipo'>
                Somos un equipo interdisciplinario de la Universidad Nacional Autónoma de México, integrado por jóvenes universitarias interesadas en la promoción de la educación cívica en México.<br /><br />
                Este proyecto se ha llevado a cabo con muchos desvelos, mucha dedicación y con la esperanza de que las voces de niñas, niños y adolescentes se escuchen y retomen en políticas públicas en favor de ellas, ellos y todo el país
              </p>
          </Col>
          <Col xs={12}>
            <Row className="equipo">
              {equipo.map((member, index) => {
                return (
                  <Col
                    xs={6}
                    md={4}
                    lg={3} 
                    key={`member_${index}`}
                  >
                    <Persona
                      name={member.name}
                      photo={member.photo}
                      description={member.description}
                      email={member.email}
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>
  );
}

export default Equipo;
