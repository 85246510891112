import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { estados } from './estados';
import './pais.css';

const Estado = ({name, id, figure, updateEstadoIndex, currentEstadoIndex}) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {name}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <path
        id={`estado_${id}`}
        data-name={name}
        className={`svg-estado cls-2${ currentEstadoIndex === id ? ' selected' : '' }`}
        d={figure}
        onClick={() => updateEstadoIndex(id)}
      />
    </OverlayTrigger>
  );
};

const Pais = ({updateEstadoIndex, currentEstadoIndex}) => {

  return (
    <div className="pais">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 150 1000 750">
        <g id="EDOS">
          {
            estados.map((estado, index) => {
              if (index === 0) {
                return null;
              }
              return (
                <Estado
                  key={index}
                  name={estado.name}
                  id={`${index}`}
                  figure={estado.figure}
                  currentEstadoIndex={currentEstadoIndex}
                  updateEstadoIndex={updateEstadoIndex}
                />
              );
            })
          }
        </g>
      </svg>
    </div>
  )
}

export default Pais