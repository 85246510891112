import React from 'react'
import './section_container.css'

const index = (props) => {
  const { children } = props;
  return (
    <div className='containerScroll' id='myScroll'>
      {children}
    </div>
  )
}

export default index
